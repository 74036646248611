import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '../components/global/Layout'
import SEO from '../components/global/Seo'

const ChapterTemplate = ({ data }) => {
  const { title, description, sections } = data.strapiChapter

  // Sort the Sections by section_code
  const sectionsSorted = sections.sort((a, b) => {
    if (a.section_code < b.section_code) {
      return -1
    }
    if (a.section_code > b.section_code) {
      return 1
    }
    return 0
  })

  // Redirect to first section
  useEffect(() => {
    navigate(sectionsSorted[0].section_slug)
    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <SEO title={title} description={description} />
      <section className={`sectionBottomMarginSmall pageMargin2Col`}>
        <p className={`h3 textColorGrey`}>{title}</p>
        <p className={`pb40 textColorGrey pageMargin2Col`}>
          Redirecting to section...
        </p>
      </section>
    </Layout>
  )
}

export default ChapterTemplate

export const query = graphql`
  query ChapterTemplate($id: String!) {
    strapiChapter(id: { eq: $id }) {
      title
      description
      sections {
        section_code
        section_slug
      }
    }
  }
`
